import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button';
import Input from '../../components/input';
import { ReactComponent as Logo } from '../../ui/assets/logoLarge.svg';

export default function ProjectStartPage({ onClickNextStep, onChangeInput, personalDetails }) {
  const { t } = useTranslation();

  return (
    <div className="layout-container">
      <div className="project-start">
        <Logo />
        <div>
          <h3>{t('pages.projectStart.title')}</h3>
          <div className="subtitle">{t('pages.projectStart.subtitle')}</div>
        </div>
        <div className="project-start__input-block">
          <div className="input-block">
            <div className="input-fields">
              <Input
                key="email"
                onChange={onChangeInput}
                label={t('pages.projectStart.email.label')}
                name="email"
                value={personalDetails.email || ''}
                type="text"
              />
            </div>
          </div>
          <Button
            caption={t('buttons.nextButton')}
            onClick={onClickNextStep}
            buttonType="primary"
            icon="arrowRight"
          />
        </div>
      </div>
    </div>
  );
}
