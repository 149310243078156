import React from 'react';
import { useTranslation } from 'react-i18next';

export default function OrderConfirmation() {
  const { t } = useTranslation();

  return (
    <div>
      <h3>{t('pages.orderConfirmation.title')}</h3>
      <p>{t('pages.orderConfirmation.subtitle')}</p>
    </div>
  );
}
