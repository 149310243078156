import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CrossIcon } from '../ui/assets/cross.svg';

export default function FilterButton({ tag, onClick, selected }) {
  const { t } = useTranslation();

  const className = `filter-button ${selected ? 'filter-button--selected' : ''}`;

  return (
    <div className={className} onClick={onClick}>
      <div className="filter-button__text">{t(`styles.${tag}`)}</div>
      {selected && (
        <div className="filter-button__icon">
          <CrossIcon />
        </div>
      )}
    </div>
  );
}
