import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonContainer from '../../components/buttonContainer';
import Input from '../../components/input';
import TextArea from '../../components/textarea';
import EnumerationOptionContainer from '../../components/enumerationOptionContainer';

export default function ProjectDetails({
  onClickNextStep,
  onClickPreviousStep,
  onChangeInputProject,
  onChangeInputRoom,
  selectedBudget,
  onClickBudget,
  selectedSchedule,
  onClickSchedule,
  project,
  room,
}) {
  const { t } = useTranslation();

  return (
    <div className="layout-container">
      <h3>{t('pages.projectDetails.title')}</h3>
      <div className="subtitle">{t('pages.projectDetails.subtitle')}</div>
      <div className="input-block">
        <h4>{t('pages.projectDetails.form.dimensions.title')}</h4>
        <div className="info-text">{t('pages.projectDetails.form.dimensions.note')}</div>
        <div className="input-fields">
          <Input
            key="length"
            onChange={onChangeInputRoom}
            label={t('pages.projectDetails.form.dimensions.answer.length.label')}
            name="length"
            value={room.length || ''}
            placeholder={t('pages.projectDetails.form.dimensions.answer.length.placeholder')}
            type="text"
          />
          <Input
            key="width"
            onChange={onChangeInputRoom}
            label={t('pages.projectDetails.form.dimensions.answer.width.label')}
            name="width"
            value={room.width || ''}
            placeholder={t('pages.projectDetails.form.dimensions.answer.width.placeholder')}
            type="text"
          />
          <Input
            key="height"
            onChange={onChangeInputRoom}
            label={t('pages.projectDetails.form.dimensions.answer.height.label')}
            name="height"
            value={room.height || ''}
            placeholder={t('pages.projectDetails.form.dimensions.answer.height.placeholder')}
            type="text"
          />
        </div>
      </div>
      <div className="input-block">
        <h4>{t('pages.projectDetails.form.budget.title')}</h4>
        <div className="info-text">{t('pages.projectDetails.form.budget.note')}</div>
        <div className="input-fields">
          <EnumerationOptionContainer
            name="budget"
            options={[
              { id: 'SMALL', caption: t('pages.projectDetails.form.budget.answer.small') },
              { id: 'MEDIUM', caption: t('pages.projectDetails.form.budget.answer.medium') },
              { id: 'LARGE', caption: t('pages.projectDetails.form.budget.answer.large') },
              {
                id: 'VERY_LARGE',
                caption: t('pages.projectDetails.form.budget.answer.extraLarge'),
              },
            ]}
            selectedOption={selectedBudget}
            onClick={onClickBudget}
          />
        </div>
      </div>
      <div className="input-block">
        <h4>{t('pages.projectDetails.form.schedule.title')}</h4>
        <div className="input-fields">
          <EnumerationOptionContainer
            name="schedule"
            options={[
              { id: 'FAST', caption: t('pages.projectDetails.form.schedule.answer.fast') },
              { id: 'NORMAL', caption: t('pages.projectDetails.form.schedule.answer.normal') },
              { id: 'SLOW', caption: t('pages.projectDetails.form.schedule.answer.slow') },
            ]}
            selectedOption={selectedSchedule}
            onClick={onClickSchedule}
          />
        </div>
      </div>
      <div className="input-block">
        <h4>{t('pages.projectDetails.form.descriptionOfProject.title')}</h4>
        <TextArea
          onChange={onChangeInputProject}
          name="description"
          value={project.description || ''}
          placeholder={t('pages.projectDetails.form.descriptionOfProject.placeholder')}
        />
      </div>
      <div className="button-container-wrapper">
        <ButtonContainer
          hasPreviousStep
          previousButtonOnClick={onClickPreviousStep}
          firstButtonCaption={t('pages.projectDetails.buttons.dontKnowButton')}
          firstButtonOnClick={onClickNextStep}
          secondButtonCaption={t('buttons.nextButton')}
          secondButtonOnClick={onClickNextStep}
          secondButtonType="primary"
          secondButtonIcon="arrowRight"
        />
      </div>
    </div>
  );
}
