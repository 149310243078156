import React from 'react';
import { useTranslation } from 'react-i18next';
import RoomOptionContainer from '../../components/roomOptionContainer';
import ButtonContainer from '../../components/buttonContainer';

import { ReactComponent as roomBathroom } from '../../ui/assets/roomBathroom.svg';
import { ReactComponent as roomBedroom } from '../../ui/assets/roomBedroom.svg';
import { ReactComponent as roomDiningRoom } from '../../ui/assets/roomDiningRoom.svg';
import { ReactComponent as roomEntryway } from '../../ui/assets/roomEntryway.svg';
import { ReactComponent as roomKidsRoom } from '../../ui/assets/roomKidsRoom.svg';
import { ReactComponent as roomKitchen } from '../../ui/assets/roomKitchen.svg';
import { ReactComponent as roomLivingRoom } from '../../ui/assets/roomLivingRoom.svg';
import { ReactComponent as roomOffice } from '../../ui/assets/roomOffice.svg';
import { ReactComponent as roomOther } from '../../ui/assets/roomOther.svg';
import { ReactComponent as roomOutdoor } from '../../ui/assets/roomOutdoor.svg';

export default function RoomSelection({ selectedRoom, onClick, onClickNextStep }) {
  const { t, i18n } = useTranslation();

  const rooms = [
    { type: 'BEDROOM', name: `${t('pages.roomSelection.roomOption.bedroom')} `, icon: roomBedroom },
    {
      type: 'LIVING_ROOM',
      name: `${t('pages.roomSelection.roomOption.livingRoom')} `,
      icon: roomLivingRoom,
    },
    { type: 'KITCHEN', name: `${t('pages.roomSelection.roomOption.kitchen')} `, icon: roomKitchen },
    {
      type: 'BATHROOM',
      name: `${t('pages.roomSelection.roomOption.bathroom')} `,
      icon: roomBathroom,
    },
    {
      type: 'DINING_ROOM',
      name: `${t('pages.roomSelection.roomOption.diningRoom')} `,
      icon: roomDiningRoom,
    },
    {
      type: 'KIDS_ROOM',
      name: `${t('pages.roomSelection.roomOption.kidsRoom')} `,
      icon: roomKidsRoom,
    },
    {
      type: 'ENTRYWAY',
      name: `${t('pages.roomSelection.roomOption.entryway')} `,
      icon: roomEntryway,
    },
    { type: 'OFFICE', name: `${t('pages.roomSelection.roomOption.office')} `, icon: roomOffice },
    { type: 'OUTDOOR', name: `${t('pages.roomSelection.roomOption.outdoor')} `, icon: roomOutdoor },
    { type: 'OTHER', name: `${t('pages.roomSelection.roomOption.other')} `, icon: roomOther },
  ];

  return (
    <div className="layout-container">
      <h3>
        {`${t('pages.roomSelection.title')}
        ${t('pages.roomSelection.titleSecondLine')} `}
      </h3>
      <div style={{ marginTop: '2rem', display: 'flex', gap: '1rem' }}>
        <button
          type="button"
          onClick={() => {
            i18n.changeLanguage(i18n.language === 'fr' ? 'en' : 'fr');
          }}
        >
          Change language
        </button>
      </div>
      <div className="layout-container">
        <RoomOptionContainer rooms={rooms} selectedRoom={selectedRoom} onClick={onClick} />
      </div>
      <div className="button-container-wrapper">
        {selectedRoom.type && (
          <ButtonContainer
            isDiabled={!selectedRoom.type}
            hasPreviousStep={false}
            secondButtonCaption={t('buttons.nextButton')}
            secondButtonOnClick={onClickNextStep}
            secondButtonType="primary"
            secondButtonIcon="arrowRight"
          />
        )}
      </div>
    </div>
  );
}
