import React from 'react';
import { useTranslation } from 'react-i18next';
import ColorOptionsContainer from '../../components/colorOptionsContainer';
import ButtonContainer from '../../components/buttonContainer';

export default function ColorSelection({
  selectedColors,
  onClick,
  onClickNextStep,
  onClickPreviousStep,
}) {
  const { t } = useTranslation();

  const colors = [
    { colorCode: '#3C3934' },
    { colorCode: '#B2B4B1' },
    { colorCode: '#E1E2DC' },
    { colorCode: '#EDE8D5' },
    { colorCode: '#885633' },
    { colorCode: '#DCC6AF' },
    { colorCode: '#6A1E69' },
    { colorCode: '#9D7286' },
    { colorCode: '#796B8F' },
    { colorCode: '#A1248A' },
    { colorCode: '#D4C9D1' },
    { colorCode: '#8B1A1C' },
    { colorCode: '#DA3833' },
    { colorCode: '#D36D61' },
    { colorCode: '#CF2F6D' },
    { colorCode: '#EFC3BA' },
    { colorCode: '#CC968C' },
    { colorCode: '#D988A8' },
    { colorCode: '#EF8547' },
    { colorCode: '#EAC3A6' },
    { colorCode: '#F7CD47' },
    { colorCode: '#D6A644' },
    { colorCode: '#F4E099' },
    { colorCode: '#285D25' },
    { colorCode: '#91984B' },
    { colorCode: '#B2C9B9' },
    { colorCode: '#5DB334' },
    { colorCode: '#C5DD4B' },
    { colorCode: '#8EAD9B' },
    { colorCode: '#1D50A3' },
    { colorCode: '#47A2D9' },
    { colorCode: '#C2D5E4' },
  ];

  return (
    <div className="layout-container">
      <h3>{t('pages.colorSelection.title')}</h3>
      <div className="layout-container">
        <ColorOptionsContainer colors={colors} selectedColors={selectedColors} onClick={onClick} />
      </div>
      <div className="button-container-wrapper">
        <ButtonContainer
          hasPreviousStep
          previousButtonOnClick={onClickPreviousStep}
          firstButtonCaption={t('pages.colorSelection.buttons.dontKnowButton')}
          firstButtonOnClick={onClickNextStep}
          secondButtonCaption={t('buttons.nextButton')}
          secondButtonOnClick={onClickNextStep}
          secondButtonType="primary"
          secondButtonIcon="arrowRight"
        />
      </div>
    </div>
  );
}
