import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import NavBar from './components/navbar';
import LoginPage from './pages/login-page/loginPage';
import ProjectSteps from './pages/project-steps/projectSteps';

function App() {
  const [wizardStep, setWizardStep] = useState(0);
  const { isAuthenticated, isLoading, user } = useAuth0();

  const handleChangeStep = (step) => {
    setWizardStep(step);
  };

  return (
    <div className="app">
      {false && !isAuthenticated && !isLoading && !user && <LoginPage />}
      <Router>
        <NavBar step={wizardStep} showWizard />
        <Routes>
          <Route
            path="/"
            element={<ProjectSteps wizardStep={wizardStep} changeWizardStep={handleChangeStep} />}
          />
          <Route
            path="/new-project"
            element={<ProjectSteps wizardStep={wizardStep} changeWizardStep={handleChangeStep} />}
          />
          <Route
            path="/project/:id"
            element={<ProjectSteps wizardStep={wizardStep} changeWizardStep={handleChangeStep} />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
