import React from 'react';
import FilterButton from './filterButton';

export default function FilterButtonContainer({ tags, onFilterChange, activeFilters }) {
  return (
    <div className="filter-button-container">
      <div className="filter-button-container__inner-container">
        {tags.map((tag) => (
          <FilterButton
            key={tag}
            tag={tag}
            onClick={() => onFilterChange(tag)}
            selected={
              tag === 'AllStyles' ? activeFilters.length === 0 : activeFilters.includes(tag)
            }
          />
        ))}
      </div>
    </div>
  );
}
