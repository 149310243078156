import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonContainer from '../../components/buttonContainer';
import StyleOptionContainer from '../../components/styleOptionsContainer';
import FilterButtonContainer from '../../components/FilterButtonContainer'; // Added import
import blueishStyle from '../../ui/assets/blueishStyle.png';
import classicStyle from '../../ui/assets/classicStyle.png';
import cozyStyle from '../../ui/assets/cozyStyle.png';
import grayStyle from '../../ui/assets/grayStyle.png';
import lightScandiStyle from '../../ui/assets/lightScandiStyle.png';
import organicStyle from '../../ui/assets/organicStyle.png';
import Button from '../../components/button';
import Dropzone from '../../components/dropzone';

export default function StyleSelection({
  selectedStyles,
  onClick,
  onClickNextStep,
  onClickPreviousStep,
  onChangeFiles,
  files,
  onClickDelete,
}) {
  const [showUpload, setShowUpload] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const { t } = useTranslation();

  const allStyles = [
    { styleName: 'SCANDINAVIAN', image: lightScandiStyle, tags: ['Modern'] },
    { styleName: 'TRADITIONAL', image: grayStyle, tags: ['Traditional'] },
    { styleName: 'INDUSTRIAL', image: cozyStyle, tags: ['Urban'] },
    { styleName: 'ECLECTIC', image: blueishStyle, tags: ['Unique'] },
    { styleName: 'FARMHOUSE', image: organicStyle, tags: ['Traditional'] },
    { styleName: 'CLASSIC', image: classicStyle, tags: ['Traditional'] },
  ];

  const allTags = ['AllStyles', ...new Set(allStyles.flatMap((style) => style.tags))];

  const filteredStyles =
    activeFilters.length > 0
      ? allStyles.filter((style) => style.tags.some((tag) => activeFilters.includes(tag)))
      : allStyles;

  const handleFilterChange = (tag) => {
    if (tag === 'AllStyles') {
      setActiveFilters([]);
    } else {
      setActiveFilters((prevFilters) => {
        const newFilters = prevFilters.includes(tag)
          ? prevFilters.filter((filter) => filter !== tag)
          : [...prevFilters, tag];
        return newFilters;
      });
    }
  };

  return (
    <div className="layout-container">
      {showUpload && (
        <Button
          caption={t('pages.styleSelection.buttons.uploadBackButton')}
          onClick={() => setShowUpload(false)}
          buttonType="link"
          icon="arrowLeft"
        />
      )}
      {showUpload && (
        <div className="style-selection__header">
          <div>
            <h3>{t('pages.styleSelection.titleUpload')}</h3>
            <div className="subtitle">{t('pages.styleSelection.subtitleUpload')}</div>
          </div>
        </div>
      )}
      {!showUpload && (
        <div className="style-selection__header">
          <div>
            <h3>{t('pages.styleSelection.title')}</h3>
            <div className="subtitle">{t('pages.styleSelection.subtitle')}</div>
          </div>
          <Button
            caption={t('pages.styleSelection.buttons.uploadButton')}
            onClick={() => setShowUpload(true)}
            buttonType="default"
            icon="upload"
          />
        </div>
      )}
      {!showUpload && (
        <>
          <div style={{ height: '3.1rem' }} />

          <div className="filter-title" style={{ marginBottom: '10px', fontWeight: 600 }}>
            {t('pages.styleSelection.buttons.filterByStyle')}
          </div>
          <FilterButtonContainer
            tags={allTags}
            onFilterChange={handleFilterChange}
            activeFilters={activeFilters}
          />

          <div className="layout-container">
            <StyleOptionContainer
              styles={filteredStyles}
              selectedStyles={selectedStyles}
              onClick={onClick}
            />
          </div>
        </>
      )}
      {showUpload && (
        <div className="layout-container">
          <Dropzone onChange={onChangeFiles} files={files} onClickDelete={onClickDelete} />
        </div>
      )}
      <div className="button-container-wrapper">
        <ButtonContainer
          hasPreviousStep
          previousButtonOnClick={onClickPreviousStep}
          firstButtonCaption={t('pages.styleSelection.buttons.dontKnowButton')}
          firstButtonOnClick={onClickNextStep}
          secondButtonCaption={t('buttons.nextButton')}
          secondButtonOnClick={onClickNextStep}
          secondButtonType="primary"
          secondButtonIcon="arrowRight"
        />
      </div>
    </div>
  );
}
