import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './button';

export default function ButtonContainer({
  hasPreviousStep,
  previousButtonOnClick,
  firstButtonCaption,
  firstButtonOnClick,
  firstButtonType,
  firstButtonIcon,
  secondButtonCaption,
  secondButtonOnClick,
  secondButtonType,
  secondButtonIcon,
  isDisabled,
}) {
  const { t } = useTranslation();

  return (
    <div className="button-container">
      <div className="button-container__inner-container">
        {hasPreviousStep && (
          <Button
            caption={t('buttons.backButton')}
            onClick={previousButtonOnClick}
            buttonType="link"
            icon="arrowLeft"
            isDisabled={isDisabled}
          />
        )}
        {firstButtonCaption && (
          <Button
            caption={firstButtonCaption}
            onClick={firstButtonOnClick}
            buttonType={firstButtonType}
            icon={firstButtonIcon}
            isDisabled={isDisabled}
          />
        )}
        {secondButtonCaption && (
          <Button
            caption={secondButtonCaption}
            onClick={secondButtonOnClick}
            buttonType={secondButtonType}
            icon={secondButtonIcon}
            isDisabled={isDisabled}
          />
        )}
      </div>
    </div>
  );
}
